import { AccountType, CustomerRole, ResidentialType } from './_.dto'
import { AccountInfoDto, ProfileDto } from './account.dto'
import { BookingDto } from './booking.dto'
import { PaymentTerm } from './invoice.dto'

export enum COUNTRY_CODE {
  _65 = '+65',
  _86 = '+86',
  _60 = '+60',
  _62 = '+62',
  _84 = '+84'
}

export interface NewCustomerRequestBodyDto {
  email?: string
  password: string
  name: string
  contact: string
  countryCode?: string
  avatarId?: string
  avatarIsTemporary?: boolean
  customerRole: CustomerRole
  organizationName?: string
  remark?: string
  paymentTerm?: PaymentTerm
  paymentTermValue?: number
  designatedAddressLine1?: string
  designatedAddressLine2?: string
  designatedAddressPostalCode?: string
  designatedAddressCity?: string
  designatedAddressCountry?: string
  designatedResidentialType?: ResidentialType

  billingAddressLine1?: string
  billingAddressLine2?: string
  billingAddressPostalCode?: string
  billingAddressCity?: string
  billingAddressCountry?: string
  billingResidentialType?: ResidentialType

  isSameDesignatedAddress?: boolean
  subContacts?: CustomerSubContact[]
}

export interface UpdateCustomerRequestBodyDto {
  name: string
  email?: string
  contact: string
  countryCode?: string
  avatarId?: string
  avatarIsTemporary?: boolean

  customerRole: CustomerRole
  organizationName?: string
  paymentTerm?: PaymentTerm
  paymentTermValue?: number

  remark?: string

  designatedAddressLine1?: string
  designatedAddressLine2?: string
  designatedAddressPostalCode?: string
  designatedAddressCity?: string
  designatedAddressCountry?: string
  designatedResidentialType?: ResidentialType

  billingAddressLine1?: string
  billingAddressLine2?: string
  billingAddressPostalCode?: string
  billingAddressCity?: string
  billingAddressCountry?: string
  billingResidentialType?: ResidentialType

  isSameDesignatedAddress?: boolean
  subContacts?: CustomerSubContact[]
}

export enum AddressType {
  Designated = 'DESIGNATED',
  Billing = 'BILLING'
}

export const AddressTypeLabel = {
  [AddressType.Designated]: 'Designated',
  [AddressType.Billing]: 'Billing'
}

export interface CustomerAddressDto {
  accountId: string
  addressName?: string
  addressLine1: string
  addressLine2: string
  addressPostalCode: string
  addressCity: string
  addressCountry: string
  residentialType?: ResidentialType
  isPrimary?: boolean
  id?: string
  addressType?: AddressType
}

export interface TempCustomerAddressDto {
  addressName: string
  addressLine1: string
  addressLine2: string
  addressPostalCode: string
  addressCity: string
  addressCountry: string
  residentialType: ResidentialType
  isPrimary: boolean
  addressType: AddressType
}

export interface ListAddressoResponseDto {
  items: CustomerAddressDto[]
  count: number
}

export interface UpdateCustomerAddressDto {
  accountId: string
  addressType: AddressType
  addressName: string
  addressLine1: string
  addressLine2: string
  addressPostalCode: string
  addressCity: string
  addressCountry: string
  residentialType?: ResidentialType
  isPrimary: boolean
}

export interface CustomerSubContact {
  countryCode: string
  contact: string
  id?: string
}

export enum DataRetentionEnum {
  OneMonth = 'ONE_MONTH',
  SixMonth = 'SIX_MONTHS',
  OneYear = 'ONE_YEAR',
  ThreeYear = 'THREE_YEARS',
  Fiveyear = 'FIVE_YEARS',
  Indefinite = 'INDEFINITE'
}

export enum DataRetentionLabel {
  OneMonth = '1 Month',
  SixMonth = '6 Months',
  OneYear = '1 Year',
  ThreeYear = '3 Years',
  Fiveyear = '5 Years',
  Indefinite = 'Indefinite'
}

export interface CustomerDataRetention {
  period: DataRetentionEnum
}

export interface ListReferralResponseDto {
  items: ReferralDto[]
  count: number
}

export interface ReferralDto {
  referred: {
    id: string
    email: string
    accountType: AccountType
    profile: ProfileDto
    employeeRoleId: string | null
    requestDeactiveDate: string | null
  }
  status: ReferralStatus
  createdAt: string
}

export enum ReferralStatus {
  Pending = 'pending',
  Completed = 'completed'
}

export interface PointLogsFilterQuery {
  offset?: number
  limit?: number
  search?: string
  all?: boolean
  sortBy?: string
  sortOrder?: 'DESC' | 'ASC'
}

export interface PointExtensionRequestFilterQuery {
  offset?: number
  limit?: number
  search?: string
  all?: boolean
  sortBy?: string
  accountId?: string
}

export interface PointLogDto {
  id: string
  point: number
  remainingPoint: number
  pointOperation: string
  status: string
  title: string
  referral: AccountInfoDto
  referred: {
    id: string
    email: string
    accountType: string
    socialLogin: any
    isActive: boolean
    profile: ProfileReffered
    employeeRoleId: any
    requestDeactiveDate: any
  }
  booking: BookingDto
  type: string
  expiresOn: string
  firstBookingDate: any
  bookingId: string
  accountId: string
  referredId: string
  referralId: string
  createdAt: string
  actionName: string
}

interface ProfileReffered {
  name: string
  contact: string
  countryCode: string
  avatarId: string
  avatarUrl: string
  firstName: string
  middleName: string
  lastName: string
  referralCode: string
  subContacts: any[]
}

export interface ListPointLogResponseDto {
  items: PointLogDto[]
  count: number
}

export interface PointExpiryExtensionRequestDto {
  id: string
  createdAt: string
  modifiedAt: string
  point: number
  reason: string
  status: ExpiryExtensionRequestStatus
  accountId: string
  code: number
  reasonReject: string
  newExpiryDate: string
  requestId: string
}

export enum ExpiryExtensionRequestStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export interface ListExtensionRequestResponseDto {
  items: PointExpiryExtensionRequestDto[]
  count: number
}

export enum TimeUnitEnum {
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months'
}

export interface ReminderFrequencyItem {
  value: number | string
  timeUnit: TimeUnitEnum | string
}

export interface ServiceReminderFrequencyDto {
  setAsDefault: boolean
  reminds: ReminderFrequencyItem[]
}

export enum PointOperation {
  Add = 'Add',
  Minus = 'Minus'
}
